<template>
  <el-dialog
    title="转发至"
    :visible.sync="dialogVisible"
    center
    width="840px"
    :before-close="handleClose">

    <el-transfer
      v-model="values"
      style="width: 800px"
      filterable
      :filter-method="filterMethod"
      :render-content="renderFunc"
      filter-placeholder="输入搜索"
      :titles="['会话', '已选择']"
      :props="{
        key: 'conversation_id',
        label: 'title'
      }"
      :data="conversationList">
    </el-transfer>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { mapState } from "vuex";
import { thumb } from "@/utils/utils";

export default {
  name: "DialogRetransmission",
  data() {
    return {
      dialogVisible: false,
      messageId: '',
      keyword: '',
      datas: [],
      values: [],
      renderFunc(h, option) {
        const avatar = thumb(option.thumb_avatar)
        return <span style="display: flex;"><img src={ avatar } style="width: 30px; height: 30px; margin-right: 5px;"/>{ option.title }</span>;
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user
    }),
    conversationList() {
      const that = this
      return this.$store.state.yeim.conversationList.filter((item) => {
        if (that.keyword !== '' && item.title && item.title.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) {
          return false;
        }
        return true;
      })
    }
  },
  methods: {
    toggle(messageId) {
      if (messageId) {
        this.messageId = messageId
        this.dialogVisible = true
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    filterMethod(query, item) {
      return item.title.indexOf(query) > -1;
    },
    handleSubmit() {
      const that = this
      if (that.values.length == 0) {
        this.$message.error('请选择要转发会话');
        return
      }
      requestHttp('message/retransmission', {
        'message_id': that.messageId,
        'values': that.values
      }).then((res) => {
        if (res.code == 200) {
          that.handleClose()
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>
