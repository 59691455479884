<template>
  <el-dialog
    :title="searchForm.id ? `修改等级` : `新增等级`"
    :visible.sync="dialogVisible"
    append-to-body
    center
    width="600px"
    :before-close="handleClose">

    <el-form :model="postForm" :rules="rules">
      <el-form-item label="图标" :label-width="formLabelWidth" prop="icon">
        <el-upload
          class="avatar-uploader"
          action="asdf"
          :auto-upload="true"
          :show-file-list="false"
          :before-upload="uploadPicture">
          <el-image :src="thumb(postForm.icon)" style="width: 75px; height: 75px"/>
        </el-upload>
      </el-form-item>
      <el-form-item label="提款图" :label-width="formLabelWidth" prop="icon">
        <el-upload
          class="avatar-uploader"
          action="asdf"
          :auto-upload="true"
          :show-file-list="false"
          :before-upload="uploadWithdrawPic">
          <el-image :src="thumb(postForm.pic)" style="width: 75px; height: 75px"/>
        </el-upload>
      </el-form-item>
      <el-form-item label="名称" :label-width="formLabelWidth" prop="title">
        <el-input v-model="postForm.title" placeholder="等级名称"></el-input>
      </el-form-item>
      <el-form-item label="简介" :label-width="formLabelWidth" prop="des">
        <el-input v-model="postForm.des" placeholder="列表中的简介"></el-input>
      </el-form-item>
      <el-form-item label="说明" :label-width="formLabelWidth">
        <el-input type="textarea" v-model="postForm.content" placeholder="输入前台的详细说明"></el-input>
      </el-form-item>
      <el-form-item label="是否循环" :label-width="formLabelWidth">
        <el-select v-model="postForm.is_loop">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="自动升级" :label-width="formLabelWidth">
        <el-select v-model="postForm.auto_upgrade">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否完成" :label-width="formLabelWidth">
        <el-select v-model="postForm.is_finish">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否当前" :label-width="formLabelWidth">
        <el-select v-model="postForm.is_current">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" :label-width="formLabelWidth">
        <el-select v-model="postForm.status">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { blobToBase64, coverTime, thumb } from '@/utils/utils'

export default {
  name: "DialogTaskLevelEdit",
  data() {
    return {
      dialogVisible: false,
      formLabelWidth: '140px',
      searchForm: {
        id: 0,
        page: 1,
        total: 0,
        limit: 20
      },
      chains: [],
      specification: [],
      default: {
        id: 0,
        user_id: '',
        icon: '',
        level: '',
        title: '',
        des: '',
        content: '',
        need_task: '0',
        money_min: '0',
        commission: '',
        is_finish: 0,
        auto_upgrade: 0,
        is_loop: 0,
        is_current: 0,
        status: 1
      },
      postForm: {
        id: 0,
        user_id: '',
        icon: '',
        level: '',
        title: '',
        des: '',
        content: '',
        need_task: '0',
        money_min: '0',
        commission: '',
        is_finish: 0,
        auto_upgrade: 0,
        is_loop: 0,
        is_current: 0,
        status: 1
      },
      rules: {
        icon: [
          { required: true, message: '请上传ICON', trigger: 'blur' }
        ],
        pic: [
          { required: true, message: '请上传提款图片', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        des: [
          { required: true, message: '请输入简介', trigger: 'blur' }
        ],
        need_task: [
          { required: true, message: '请输入需要的次数', trigger: 'blur' }
        ],
        money_min: [
          { required: true, message: '请输入任务金额', trigger: 'blur' }
        ],
        commission: [
          { required: true, message: '请输入佣金比例', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    coverTime,
    thumb,
    uploadPicture(file) {
      const that = this
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
        return false
      }

      blobToBase64(file, function (res) {
        that.postForm.icon = res
      });

      return false;
    },
    uploadWithdrawPic(file) {
      const that = this
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
        return false
      }

      blobToBase64(file, function (res) {
        that.postForm.pic = res
      });

      return false;
    },
    toggle(id, userId) {
      this.searchForm.id = id
      this.dialogVisible = true
      this.postForm = JSON.parse(JSON.stringify(this.default))
      this.postForm.user_id = userId
      if (id) {
        this.fetchData()
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/get_task_level_info', this.searchForm).then((res) => {
        if (res.code == 200) {
          that.postForm = res.data.detail
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleSubmit() {
      const that = this
      requestHttp('agent/save_task_level', this.postForm).then((res) => {
        if (res.code == 200) {
          that.$emit('change');
          that.$message.success(res.message)
          that.handleClose()
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
