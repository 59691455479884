<template>
  <el-dialog
    title="创建群聊"
    :visible.sync="dialogVisible"
    center
    width="500px"
    :before-close="handleClose">

    <div title="基本资料">
      <div class="example">
        <el-form ref="baseForm" label-width="120px" :modelValue="baseFormData">
          <el-form-item label="群名" required>
            <el-input v-model="baseFormData.name" placeholder="请输入昵称" />
          </el-form-item>
          <el-form-item label="群介绍">
            <el-input type="textarea" v-model="baseFormData.introduction" placeholder="请输入群介绍" />
          </el-form-item>
          <el-form-item label="选择托号">
            <el-checkbox-group v-model="baseFormData.sub_accounts">
              <el-checkbox v-for="(account, idx) in accounts" :label="account.user_id" :key="idx">{{account.user_id}} ({{account.nickname}})</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :disabled="createDisable" @click="handleSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { blobToBase64, thumb } from "@/utils/utils";

export default {
  name: "DialogCreateGroup",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      accounts: [],
      createDisable: false,
      baseFormData: {
        user_ids: '',
        avatar: '',
        name: '',
        join_mode: 2,
        introduction: '',
        sub_accounts: []
      }
    }
  },
  methods: {
    thumb,
    toggle(userId) {
      if (userId) {
        this.userId = userId
        this.fetchAccount()
        this.createDisable = false
        this.dialogVisible = true
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    uploadAvatar(file) {
      const that = this
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
        return false
      }

      blobToBase64(file, function (res) {
        that.baseFormData.avatar = res
      });

      return false;
    },
    fetchAccount() {
      const that = this
      that.accounts = []
      requestHttp('agent/get_virtual_users', {}).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
          return;
        }
        res.data.forEach(item => {
          if (item.virtual_type == 2) {
            that.accounts.push(item)
          }
        })
      })
    },
    handleSave() {
      // setSendSettingForm.default
      const that = this

      if (that.baseFormData.sub_accounts.length == 0) {
        this.$confirm('未选择托号，将无法打开托号窗口，确定吗？',  '提示').then(function () {
          that.baseFormData.user_ids = that.userId + ',' + that.baseFormData.sub_accounts.join(',')

          that.createDisable = true
          requestHttp('group/create', that.baseFormData).then((res) => {
            if (res.code == 200) {
              that.$message.success({ message: res.message, offset: 100 })
              that.$emit('confirm',  res.data)
              that.handleClose()
            } else {
              that.createDisable = false
              that.$message.error(res.message)
            }
          })
        })
      } else {
        that.baseFormData.user_ids = that.userId + ',' + that.baseFormData.sub_accounts.join(',')

        that.createDisable = true
        requestHttp('group/create', that.baseFormData).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.$emit('confirm', res.data)
            that.handleClose()
          } else {
            that.createDisable = false
            that.$message.error(res.message)
          }
        })
      }
    },
  }
}
</script>

<style scoped>
.avatar-upload {
  text-align: center;
  padding-bottom: 20px;
}
</style>
