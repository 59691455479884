<template>
  <el-dialog
    title="出金"
    :visible.sync="dialogVisible"
    center
    width="800px"
    :before-close="handleClose">
    <el-button type="primary" @click="handleShowDuplicateCheck()" style="margin-bottom: 10px">查重</el-button>
    <el-button type="primary" @click="fetchData">刷 新</el-button>
    <el-table
      height="300"
      :data="datas"
      border>
      <el-table-column
        prop="create_time"
        label="日期"
        width="100">
      </el-table-column>
      <el-table-column
        prop="user_id"
        label="UserID"
        width="120">
      </el-table-column>
      <el-table-column
        prop="money"
        label="金额"
        width="120">
      </el-table-column>
      <el-table-column
        prop="code"
        label="提款码"
        width="80">
      </el-table-column>
      <el-table-column
        prop="copy"
        label="复制"
        width="80">
        <template slot-scope="scope">
          <a @click="copyVal(scope.row)" style="color: blue">复制</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="status_str"
        label="状态">
      </el-table-column>
      <el-table-column
        prop="audit_user"
        label="操作人">
      </el-table-column>
      <el-table-column
        width="200"
        label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <div v-if="scope.row.status == 3 && scope.row.reason != ''">理由：{{scope.row.reason}}</div>
            <el-button type="danger" size="mini" v-if="scope.row.status === 3" @click="handleEditReason(scope.row.id)">
              修改
            </el-button>
            <el-button type="primary" size="mini" v-if="scope.row.status === 1" @click="handlePass(scope.row.id)">提交</el-button>
            <el-button type="warning" size="mini" v-if="scope.row.status === 1" @click="handleRefuse(scope.row.id)">拒绝</el-button>
            <el-button type="danger" size="mini" v-if="scope.row.status === 1" @click="handleOver(scope.row.id)">假通过</el-button>
            <el-button type="danger" size="mini" v-if="scope.row.status === 5" @click="handleRecover(scope.row.id)">恢复</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="fetchData">刷 新</el-button>
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="danger" @click="handleAllOver()">一键假通过</el-button>
      <el-button type="primary" @click="handleShowDuplicateCheck()">查重</el-button>
    </div>
    <dialog-duplicate-check ref="dialogDuplicateCheck"/>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import DialogDuplicateCheck from "@/views/chat/components/dialog-duplicate-check";

export default {
  name: "DialogWithdrawLog",
  components: {
    DialogDuplicateCheck
  },
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      datas: []
    }
  },
  methods: {
    toggle(userId) {
      this.userId = userId
      this.dialogVisible = true
      this.fetchData()
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleShowDuplicateCheck() {
      this.$refs.dialogDuplicateCheck.toggle(this.userId)
    },
    // 复制操作
    copyVal(row) {
      let context = row.user_id + ' 出' + row.money
      // 创建输入框元素
      let oInput = document.createElement('textarea');
      // 将想要复制的值
      oInput.innerHTML = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    },
    fetchData() {
      const that = this
      that.datas = [];
      requestHttp('agent/get_withdraw_log', {
        'user_id': that.userId,
        limit: 100
      }).then((res) => {
        if (res.code == 200) {
          that.datas = res.data.data
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handlePass(id)
    {
      const that = this
      this.$confirm('确定通过此出金？',  '提示').then(function () {
        requestHttp('agent/pass_withdraw', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleRefuse(id)
    {
      const that = this
      this.$prompt('请输入拒绝理由', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        inputPlaceholder: '这个是显示客户看的，别输入中文',
      }).then(({ value }) => {
        requestHttp('agent/refuse_withdraw', {
          'id': id,
          reason: value
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleEditReason(id)
    {
      const that = this
      this.$prompt('请输入新的拒绝理由', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        inputPlaceholder: '拒绝理由',
      }).then(({ value }) => {
        requestHttp('agent/refuse_withdraw_edit_reason', {
          'id': id,
          reason: value
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleOver(id)
    {
      const that = this
      this.$confirm('确定设置为假通过？',  '提示').then(function () {
        requestHttp('agent/virtual_over_withdraw', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleAllOver()
    {
      const that = this
      this.$confirm('确定将所有状态为待审核的设置为假通过？',  '提示').then(function () {
        requestHttp('agent/virtual_all_over_withdraw', {user_id: that.userId}).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handleRecover(id)
    {
      const that = this
      this.$confirm('确定恢复为待审状态？',  '提示').then(function () {
        requestHttp('agent/recover_withdraw', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            that.fetchData()
          } else {
            that.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
