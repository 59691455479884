<template>
  <div class="content-wrapper">
    <div class="chat-content" style="border: 0">
      <div class="chat-title" style="font-size: 14px">
        <el-avatar shape="square" :size="30" :src="thumb(account.thumb_avatar)" style="margin-right: 10px" />
        {{ account.user_id }} ({{ account.nickname }})
      </div>
      <div class="chat-textarea">
        <div class="chat-btn-wrapper">
          <div class="left-wrapper">
            <div class="icon-item" @click.stop="isEmoji = !isEmoji">
              <el-icon class="el-icon-eleme"/>
            </div>
            <div class="icon-item">
              <el-upload
                class="avatar-uploader"
                action="aaa"
                :auto-upload="true"
                :show-file-list="false"
                :before-upload="beforeUpload">
                <el-icon class="el-icon-picture" />
              </el-upload>
            </div>
            <div class="icon-item" @click.stop.stop="isMsg = true"><span class="iconfont iconliaotian"></span></div>
          </div>
        </div>
        <div class="textarea-box" style="position:relative;">
          <vue-tribute class="editable" :options="tributeOptions">
            <div ref="editChatCon" :id="`editChatCon_` + account.user_id" contenteditable="true" class="editable" autosize @paste="handlePaste"
              @input="handleInput" @keydown="handleKeyup"/>
          </vue-tribute>
          <div class="send-btn" v-if="1==2">
            <el-button class="btns" type="primary" :disabled="disabled" @click.stop="sendText">发送</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择表情" :visible.sync="isEmoji" width="700px">
      <!-- 表情 -->
      <div class="emoji-box">
        <div v-for="(item, idx) in faces" :key="idx" style="display: flex;justify-content: center;" @click="emojiClick(item.key)">
          <el-image style="width: 30px;height: 30px"
            :src="thumb('/statics/face/'+ item.path)" mode=""></el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { clearStorage, fileToBlob, thumb } from "@/utils/utils";
import { mapState } from "vuex";
import {
  YeIMUniSDK
} from '@/utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min';
import VueTribute from 'vue-tribute';
import $ from "jquery";
import face from '@/utils/face.js';

export default {
  name: "SubWindow",
  props: {
    account: {
      type: Object,
      default: {}
    },
    conversation: {
      type: Object,
      default: {
        'type': 'private'
      }
    },
    tributeOptions: {
      type: Object,
      default: {
        trigger: '@',
        values: []
      }
    }
  },
  components: {
    VueTribute
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user
    }),
    disabled() {
      if (this.chatCon.length == 0) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      faces: face.faces,
      faceObject: face.faceObject,
      isEmoji: false, // 是否显示表情弹框
      chatCon: '' // 输入框输入的聊天内容
    }
  },
  methods: {
    thumb,
    handleInput() {
      this.chatCon = this.$refs.editChatCon.innerHTML.replace(/(^\s+)/g,"");
    },

    handleKeyup($event) {
      var keycode = window.event ? $event.keyCode : $event.which;
      var evt = $event || window.event;

      //回车-->发送消息
      if (keycode == 13 && !(evt.ctrlKey)) {
        $event.preventDefault();
        this.sendText();
        return false;
      }

      //ctrl+回车-->换行
      if (evt.ctrlKey && evt.keyCode == 13) {
        $event.preventDefault();
        $('#editChatCon').append('<br/><br/>')
        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
        return false;
      }
    },

    handlePaste(event) {
      const that = this
      event.preventDefault();
      let clipboardDataItem = event.clipboardData.items[0];
      if (clipboardDataItem.type.indexOf('image/') != -1) {
        let file = clipboardDataItem.getAsFile();
        this.sendFile(file)
        this.$refs.editChatCon.innerText = '';
        this.chatCon = ''
      } else if (clipboardDataItem.type.indexOf('text/') != -1) {
        clipboardDataItem.getAsString(function(res) {
          let selection = document.getSelection()
          // 光标对象
          let range = selection.getRangeAt(0)
          let objE = document.createElement("span");
          objE.innerHTML = res.replaceAll('\n', '<br>')
          range.insertNode(objE)
          // document.execCommand('selectAll', false, null);
          document.getSelection().collapseToEnd();
          that.handleInput()
          // $('#editChatCon_' + that.account.user_id).append(res.replaceAll('\n', '<br>'))
          // document.execCommand('selectAll', false, null);
          // document.getSelection().collapseToEnd();
          // that.handleInput()
        })
      }
    },

    handleFormatError(file) {
      this.$Message.error("上传图片只能是 jpg、jpg、jpeg、gif 格式!");
    },

    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
      }

      this.sendFile(file)

      return false;
    },
    sendFile(file) {
      const that = this
      fileToBlob(file, function (res) {
        let message = YeIMUniSDK.getInstance().createImageMessage({
          toId: that.conversation.conversationId,
          conversationType: that.conversation.type,
          body: {
            file: {
              tempFilePath: res,
              width: 1,
              height: 1
            }
          }
        });
        message.sub_account = that.account.user_id
        if (message.code) {
          that.$message.error(message.message)
          return
        }
        YeIMUniSDK.getInstance().sendMessage({
          message: message,
          success: (res) => {
          },
          fail: (err) => {
            // 发送失败
            if (err.code == 10003) {
              clearStorage()
              that.$router.push({ name: 'UserLogin' });
              return;
            }
            if (err.code == 400) {
              that.$message.error(err.message)
              return;
            }
          }
        });
      })
    },
    // 输入框选择表情
    emojiClick(data) {
      let val = `[face_${data}]`
      this.$refs.editChatCon.innerHTML += val
      this.chatCon += val;
      this.isEmoji = false
    },
    parseText(text) {
      var regex = /\[(.+?)\]/g;
      let emojis_char = text.match(regex);
      if (emojis_char) {
        for (let i = 0; i < emojis_char.length; i++) {
          let char = emojis_char[i];
          char = char.replace("[", "");
          char = char.replace("]", "");
          let index = char.split("_")[1];
          let image = "<img style='width:20px;height:20px' src='assets/image/face/Expression_" + index +
            "@2x.png' />";
          text = text.replace(emojis_char[i], image);
        }
      }
      return text;
    },
    // 话术选中
    activeTxt(data) {
      this.chatCon += data;
      this.isMsg = false
    },
    // 文本发送
    sendText() {
      this.handleInput();
      if (!this.chatCon) {
        return this.$message.error('请输入内容');
      }

      const that = this
      let message = YeIMUniSDK.getInstance().createTextMessage({
        toId: this.conversation.conversationId,
        conversationType: this.conversation.type,
        body: {
          text: this.chatCon
        },
        extra: ""
      });

      that.$refs.editChatCon.innerHTML = ''
      that.chatCon = ''

      message.sub_account = that.account.user_id

      YeIMUniSDK.getInstance().sendMessage({
        message: message,
        success: () => {
          that.$refs.editChatCon.innerHTML = ''
          that.chatCon = ''
        },
        fail: (err) => {
          // 发送失败
          if (err.code == 10003) {
            clearStorage()
            that.$router.push({ name: 'UserLogin' });
            return;
          }
          if (err.code == 400) {
            that.$message.error(err.message)
            return;
          }
        }
      });
      this.chatCon = '';
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.ivu-input {
  border: none;
  resize: none;
}
.content-wrapper {
  margin: 0 auto;
  background: #fff;
  display: flex;
  .emoji-box {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    padding: 15px 9px;
    box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.1);
    background: #fff;

    .emoji-item {
      margin-right: 13px;
      margin-bottom: 8px;
      cursor: pointer;

      &:nth-child(10n) {
        margin-right: 0;
      }
    }
  }
  .chat-title {
    background: #409fFF;
    color: #fff;
    padding: 10px 10px;
    line-height: 30px;
    font-weight: bold;
    display: flex;
    justify-content: left;
    text-align: left;

    .el-image {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 8px;
    }

    .des {

    }
  }
  .chat-content {
    width: 100%;
    height: 100%;
    border-right: 1px solid #ECECEC;

    .chat-textarea {
      display: flex;
      flex-direction: column;
      height: 130px;
      border-top: 1px solid #ECECEC;

      .chat-btn-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 0 0;

        .left-wrapper {
          display: flex;
          align-items: center;

          .icon-item {
            display: flex;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            font-size: 24px;

            .iconfont {
              color: #333333;
            }
          }
        }

        .right-wrapper {
          position: relative;
          padding-right: 20px;

          .icon-item {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #333;
            cursor: pointer;

            span {
              margin-left: 10px;
            }
          }

          .transfer-box {
            z-index: 60;
            position: absolute;
            right: 1px;
            bottom: 43px;
            width: 140px;
            background: #fff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            padding: 16px;
          }

          .transfer-bg {
            z-index: 50;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: transparent;
          }
        }
      }

      .textarea-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        text-align: left;
      }

      .editable {
        flex: 1;
        height: 70px;
        padding: 4px 4px;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 14px;
        line-height: 1.5;
        color: #515a6e;
        background: #f5f5f5;

        &:focus-visible {
          outline: 0;
        }
      }
    }
  }
}

.send-btn {
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.bg {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
</style>
