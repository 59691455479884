<template>
  <div class="content-wrapper">
    <div class="chat-content">
      <div style="overflow-x: auto">
        <div id="chat_title" class="chat-title" style="display: flex; justify-content: left; height: 100px; min-width: 480px; ">
          <div class="nickname" v-if="conversation.type" style="width: 40px;">
            <el-image width="100"
              :src="thumb(conversation.type == 'private' ? conversation.userInfo.thumb_avatar : conversation.groupInfo.thumb_avatar)"
              @click="showProfile"
            />
            <el-button type="primary" size="mini" style="padding: 5px; margin-top: 7px" @click="getUserInfo(true)" v-if="conversation.type && conversation.type == 'private'" icon="el-icon el-icon-refresh"/>
          </div>
          <div class="des" v-if="conversation.type && conversation.type == 'private'">
            <div>
              <div style="display: flex; font-size: 12px">
                <div>{{ conversation.userInfo.user_id }} ({{ conversation.userInfo.nickname }}) - <span style="color: red">{{userInfo.line_id}}</span>
                  [交易账号：{{ userInfo.last_other_account }}]
                </div>
                <div style="color: blue; display: flex; justify-content: left; padding-left: 10px">
                  <span @click="showScoreEdit=!showScoreEdit" style="width: 50px">信誉分：</span>
                  <span v-if="!showScoreEdit" @click="showScoreEdit=!showScoreEdit;showRemarkEdit=false;">{{ userInfo.score || '0' }}</span>
                  <el-input class="aaa" v-model="userInfo.score" style="width: 80px;" size="mini" placeholder="请输入分" v-else @blur="handleScoreEdit" />
                </div>
              </div>
              <div style="display: flex; justify-content: left; font-size: 12px;">
                <div style="display: inline-block; min-width: 150px; margin-right: 20px">
                  <a href="javascript:void(0)" style=" color: red">
                    余额：{{userInfo.balance}} 本金：{{userInfo.freeze_task_money}} 收益：{{userInfo.freeze_task_commission}}
                  </a>
                </div>
                <div style=" color: orangered">{{userInfo.login_info}}</div>
              </div>
              <div style="display: flex; justify-content: left; font-size: 12px;">
                <div style="color: blue; width: 100%; display: flex; justify-content: left; padding-left: 0px">
<!--                  <span style="width: 40px;">备注：</span>-->
                  <span v-if="!showRemarkEdit" @click="showRemarkEdit=!showRemarkEdit;showScoreEdit=false;">{{ userInfo.remarks || '无，点击输入' }}</span>
                  <el-input class="aaa" v-model="userInfo.remarks" size="mini" placeholder="请输入备注" v-else
                    @blur="handleRemarkEdit" />
                </div>
              </div>
            </div>
            <div class="top-buttons" v-if="conversation.type === 'private'">
              <el-popover
                ref="popoverUser"
                width="450"
                trigger="click"
                placement="bottom-start"
              >
                <el-button type="primary" @click="handleSwitchTradeStatus(1)">正常</el-button>
                <el-button type="danger" @click="handleSwitchTradeStatus(2)">禁止交易</el-button>
                <el-button type="success" @click="handleSwitchTradeStatus(3)">盈利</el-button>
                <el-button type="warning" @click="handleSwitchTradeStatus(4)">亏损</el-button>
                <el-button type="info" @click="handleSwitchTradeStatus(5)">其他</el-button>
              </el-popover>
              <el-popover
                  ref="popoverWithdraw"
                  width="260"
                  trigger="click"
                  placement="bottom-start"
              >
                <el-button type="primary" @click="handleSwitchWithdrawStatus(1)">正常</el-button>
                <el-button type="danger" @click="handleSwitchWithdrawStatus(2)">冻结</el-button>
                <el-button type="warning" @click="handleSwitchWithdrawStatus(3)">稽查</el-button>
              </el-popover>
              <el-popover
                ref="popoverToGroup"
                width="260"
                trigger="click"
                placement="bottom-start"
              >
                <div  v-if="userInfo.conversation_ids && userInfo.conversation_ids.length > 0">
                  <div v-for="(cItem, cIdx) in userInfo.conversation_ids" :key="cIdx">
                    <div
                      @click="newConversation('group', cItem.group_id)"
                      style="margin-bottom: 10px; background: #f5f5f5; border-radius: 4px; line-height: 30px; padding: 5px; display: flex; justify-content: left;"
                    >
                      <el-image :src="thumb(cItem.thumb_avatar)" style="width: 30px; height: 30px;"/>
                      <div style="margin-left: 10px">{{cItem.name}}</div>
                    </div>
                  </div>
                </div>
                <div v-else>暂无群聊</div>
              </el-popover>
              <el-button-group class="aaa">
                <el-button :type="userInfo.trade_status_type" size="mini" v-popover:popoverUser>
                  {{userInfo.trade_status_str}}
                </el-button>
                <el-button type="primary" size="mini" v-if="userInfo.withdraw_status === 1" v-popover:popoverWithdraw>未冻结
                </el-button>
                <el-button type="danger" size="mini" v-else-if="userInfo.withdraw_status === 2"  v-popover:popoverWithdraw>冻结</el-button>
                <el-button type="warning" size="mini" v-else v-popover:popoverWithdraw>稽查</el-button>
                <el-button type="primary" size="mini" @click="showGift(null)">彩金</el-button>
                <el-button type="primary" size="mini" @click="showWithdrawLog" v-if="userInfo.virtual_withdraw == 0">提现</el-button>
                <el-button type="danger" size="mini" @click="showWithdrawLog" v-else>提现假</el-button>
                <el-button type="primary" size="mini" @click="showOrderLog">订单</el-button>
                <el-button type="primary" size="mini" @click="showTaskLevel">任务</el-button>
                <el-button type="warning" size="mini" v-popover:popoverToGroup>
                  定群
                </el-button>
                <el-button type="primary" size="mini" @click="showCreateGroup">
                  建群
                </el-button>
                <el-button type="danger" size="mini" v-if="showTranslate" @click="showTranslate = !showTranslate" title="关闭翻译/彩金栏">隐藏</el-button>
                <el-button type="primary" size="mini" v-else @click="showTranslate = !showTranslate" title="显示翻译/彩金栏">显示</el-button>
                <el-button type="primary" size="mini" @click="showHistory" title="历史">历史</el-button>
                <el-button type="warning" size="mini" @click="showIp" title="IP">IP</el-button>
                <el-button type="primary" size="mini" @click="showSendMessage" title="消息">消息</el-button>
              </el-button-group>
            </div>
            <!--
            <div style="font-size: 10px; color: #aaa;">
              {{ userInfo.login_info }}
            </div>
            <div style="font-size: 10px; color: #aaa;">
              最后充值：{{ userInfo.last_deposit_time }}，最后出金：{{ userInfo.last_withdraw_time }}
            </div>
            -->
          </div>
          <div class="des group" v-else>
            <div style="width: 100%" v-if="conversation.groupInfo">{{ conversation.groupInfo.name }}</div>
            <div>
              <el-button-group class="aaa">
                <el-button type="danger" size="mini" v-if="showTranslate" @click="showTranslate = !showTranslate" title="关闭翻译/彩金栏">隐藏</el-button>
                <el-button type="primary" size="mini" v-else @click="showTranslate = !showTranslate" title="显示翻译/彩金栏">显示</el-button>

                <el-button type="primary" size="mini" @click="showEditGroup">修改群信息</el-button>
                <el-button type="warning" size="mini" @click="showGroupUser(null)">调整成员</el-button>
                <el-button type="danger" size="mini" v-if="conversation.groupInfo && conversation.groupInfo.is_mute == 1"
                  @click="handleSwitchMute(0)">禁言中
                </el-button>
                <el-button type="primary" size="mini" v-else @click="handleSwitchMute(1)">禁言</el-button>
                <el-button type="danger" size="mini" v-if="showMute && conversation.is_main === 1" @click="showMute = !showMute">隐藏禁言</el-button>
                <el-button type="primary" size="mini" v-if="!showMute && conversation.is_main === 1" @click="showMute = !showMute">显示禁言</el-button>
                <el-button type="primary" size="mini" v-if=" conversation.is_main != 1" @click="showOrderLog('aaa')">订单</el-button>
                <el-button type="primary" size="mini" @click="showHistory" title="历史">历史</el-button>
              </el-button-group>
            </div>
          </div>
        </div>
      </div>

      <div class="chat-body" :style="`height: ` + chatHeight + `px; `">
        <div id="chat_scroll" class="chat-scroll" ref="chatScroll" v-on:scroll="handleScroll"
          :style="`padding: 10px; height: ` + (chatHeight - 20) + `px; overflow-y: auto`">
          <div id="chat_scroll_content">
            <div v-loading="isLoad" element-loading-text="加载中……" t>
            </div>
            <div v-if="records.length > 0" class="chat-item" v-for="(item,index) in records" :key="index"
              :class="[{'right-box': conversation.user_id == item.from, 'system': item.type == 'system'}]"
              :id="`chat_${item.sequence}`">
              <!--              <div class="time">{{ dateTimeFormat(item.time, true) }}</div>-->
              <div v-if="item.type == 'system'" style="color: #1d42ab; font-size: 14px">
                <div class="txt-wrapper pad16" v-html="parseText(item.body.text)"></div>
              </div>
              <div class="flex-box" v-if="item.type != 'system'">
                <div class="avatar">
                  <el-image v-if="item.from != user.userId" :src="thumb(item.fromUserInfo.thumb_avatar)"
                    @contextmenu.prevent="(e) => { onUserContextmenu(e, item) }"
                  />
                  <!--
                  <el-dropdown v-if="item.from != user.userId" :command="handleDropdown">
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-if="conversation.type === 'group'" command="add">发起私聊</el-dropdown-item>
                      <el-dropdown-item v-if="conversation.type === 'private'" command="add_group">加入群组</el-dropdown-item>
                      <el-dropdown-item v-if="conversation.type === 'group'" command="kick">踢出群组</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  -->
                  <el-image v-else :src="thumb(item.fromUserInfo.thumb_avatar)" />
                  <div class="remark-nickname"
                    v-if="conversation.is_main == 1 && item.fromUserInfo.remarkNickname != '' && showTranslate">
                    {{ item.fromUserInfo.remarkNickname }}
                  </div>
                  <div class="remark-nickname"
                    style="color: red; line-height: 13px;"
                    v-else-if="conversation.is_main == 1 && item.fromUserInfo.line_id != '' && showTranslate">
                    client<br>
                    {{ item.fromUserInfo.lineId }}
                  </div>
                </div>
                <div class="msg-content"
                  @contextmenu.prevent="(e) => { onContextmenu(e, item) }"
                >
                  <div class="name" v-if="conversation.type == 'group' && showTranslate" @click="handleAddTribute(item.from, item.fromUserInfo.nickname)">{{ item.from }} <span >({{item.fromUserInfo.nickname}})</span>
                    <el-icon v-if="(conversation.is_main == 1 || conversation.is_vice == 1) && item.is_mute == 1" class="mute active el-icon-turn-off-microphone"></el-icon>
                  </div>
                  <div class="name" v-if="conversation.type == 'group' && !showTranslate" @click="handleAddTribute(item.from, item.fromUserInfo.nickname)">{{item.fromUserInfo.nickname}}</div>
                  <div class="msg-wrapper">
                    <div v-if="item.replyMessage && item.replyMessage.type !== ''" class="txt-wrapper pad16" style="background: #f5f5f5;">
                      <div style="border-left: 2px solid #ccc; padding-left: 10px; color: #999; font-size: 12px;">
                        <div style="font-weight: bold; padding: 5px 0;" >
                          {{ item.replyMessage.from }}：
                        </div>
                        <div style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: left">
                          <div style="white-space: nowrap" v-if="item.replyMessage.type == 'text'"><div v-html="item.replyMessage.body.text"></div></div>
                          <el-image v-if="item.replyMessage.type == 'image'" :src="thumb(item.replyMessage.body.thumbnailUrl)" style="height: 30px" :preview-src-list="[thumb(item.replyMessage.body.originalUrl)]" />
                          <div v-if="item.replyMessage.type == 'audio'">语音（{item.replyMessage.body.duration}秒）</div>
                        </div>
                      </div>
                    </div>
                    <!-- 文档 -->
                    <template v-if="item.type == 'text'">
                      <div class="txt-wrapper" >
                        <div>
                          <span class="pad16" v-html="parseText(item.body.text)"></span>
                          <span class="time">{{ dateTimeFormat(item.time, true) }}</span>
                        </div>
                        <div class="txt-wrapper blue pad16 transfer" v-if="showTranslate && item.body_translate">
                           <span class="pad16" v-html="parseText(item.body_translate)"></span>
                        </div>
                      </div>
                    </template>
                    <!-- 文档 -->
                    <template v-if="item.type == 'audio'">
                      <span class="txt-wrapper pad16" style="height: 100px !important;">
                        <audio controls="controls"> <source :src="thumb(item.body.audioUrl)" type="audio/mp4" /></audio><br>{{item.body.duration}}秒
                      </span>
                      <span class="time">{{ dateTimeFormat(item.time, true) }}</span>
                    </template>
                    <!-- 文档 -->
                    <template v-if="item.type == 'system'">
                      <span class="txt-wrapper pad16" v-html="parseText(item.body.text)"></span>
                      <span class="time">{{ dateTimeFormat(item.time, true) }}</span>
                    </template>
                    <!-- 图片 -->
                    <template v-if="item.type == 'image'">
                      <span class="img-wraper" :style="{width: '50px !important', height: (50/item.body.thumbnailWidth * item.body.thumbnailHeight) + 'px !important'}">
                        <el-image :src="thumb(item.body.thumbnailUrl)"
                          :width="50" :height="50/item.body.thumbnailWidth * item.body.thumbnailHeight"
                          :preview-src-list="[thumb(item.body.originalUrl)]"
                          fit="contain"
                        />
                      </span>
                      <div class="method"
                        v-if="item.from != user.userId && item.gift == 0 && conversation.type == 'private' && showTranslate">
                        <el-button type="text" size="mini"
                          v-if="item.fromUserInfo.giftLevel == 1"
                          style="padding: 0; color: red"
                          @click="switchGiftLevel(item.from, 2)">初级
                        </el-button>
                        <el-button type="text" size="mini"
                          v-else
                          style="padding: 0; color: red"
                          @click="switchGiftLevel(item.from, 1)">高级
                        </el-button>
                        <el-button type="text" size="mini"
                          style="padding: 0"
                          v-for="(item1, idx1) in getGift(item.time, item.fromUserInfo.giftLevel)"
                          :key="idx1"
                          @dblclick.native="handleSendGift(item.from, item1, item.uniqueMessageId)"
                        >{{ item1 }}
                        </el-button>
                        <el-button type="text" size="mini"
                          style="padding: 0"
                          @click="showGift(item.from, item.uniqueMessageId)">自定义
                        </el-button>
                        <el-button type="text" size="mini"
                          style="padding: 0"
                          @dblclick.native="handleSendGift(item.from, item.fromUserInfo.defaultGift, item.uniqueMessageId)"
                        >{{ item.fromUserInfo.defaultGift }}
                        </el-button>
                      </div>
                      <div class="method gift" v-if="item.from != user.userId && item.gift > 0 && showTranslate">
                        <div>{{ item.gift }}</div>
                        <el-button type="text" style="color: red" size="mini"
                          @click="handleRevocationGift(item.uniqueMessageId, item.gift)">撤回
                        </el-button>
                      </div>
                      <div class="time">{{ dateTimeFormat(item.time, true) }}</div>
                    </template>
                  </div>
                  <!--                  <div class="time">{{ dateTimeFormat(item.time, true) }}</div>-->
                  <div class="read" v-if="item.from == user.userId && conversation.is_main != 1 && conversation.is_vice != 1">
                    <span v-if="item.isSend == 0">✓</span>
                    <span v-if="item.isSend == 1 && item.readStatus == 0">✓✓</span>
                    <span class="blue" v-if="item.isSend == 1 && item.readStatus == 1">✓</span>
                    <span v-if="item.isSend == 1 && item.readStatus == 1">✓</span>
                    <span class="blue" v-if="item.isSend == 1 && item.readStatus == 2">✓✓</span>
                    <span class="blue" v-if="item.isSend == 2">发送失败</span>
                  </div>
                  <div class="read" v-else-if="item.from == user.userId && (conversation.is_main == 1 || conversation.is_vice == 1)">
                    <span v-if="item.isSend == 0">✓</span>
                    <span class="blue" v-if="item.isSend == 1">✓✓</span>
                    <span class="blue" v-if="item.isSend == 2">发送失败</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chat-textarea">
        <div v-if="replyMessage.uniqueMessageId !== ''">
          <div style="display: flex; padding: 10px 30px; background: #f5f5f5;">
            <div style="margin-right: 30px" @click="handleDropdown('cancel_reply', {})">
              <el-icon class="el-icon el-icon-close" size="64" style="background: #1d42ab; color: #fff; border-radius: 50%;"/>
            </div>
            <div style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: left">
              <div style="white-space: nowrap" v-if="replyMessage.type == 'text'" v-html="replyMessage.body.text"></div>
              <el-image v-if="replyMessage.type == 'image'" lazy :src="thumb(replyMessage.body.thumbnailUrl)" style="height: 30px" :preview-src-list="[thumb(replyMessage.body.originalUrl)]" />
              <div v-if="replyMessage.type == 'audio'">语音消息（{replyMessage.body}）</div>
            </div>
          </div>
        </div>
        <div v-if="imageMessage.uniqueMessageId !== '' && user.send_file_action == 0">
          <div style="display: flex; padding: 10px 30px; background: #f5f5f5;">
            <div style="margin-right: 30px" @click="handleDropdown('cancel_send', {})">
              <el-icon class="el-icon el-icon-close" size="64" style="background: #1d42ab; color: #fff; border-radius: 50%;"/>
            </div>
            <div style="width: 100%; display: flex; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: left">
              <div style="white-space: nowrap" v-if="imageMessage.type == 'text'" v-html="imageMessage.body.text"></div>
              <el-image v-if="imageMessage.type == 'image'" :src="thumb(imageMessage.body.thumbnailUrl)" style="height: 200px" :preview-src-list="[thumb(imageMessage.body.originalUrl)]" />
              <div v-if="imageMessage.type == 'audio'">语音消息（{imageMessage.body}）</div>
              <div style="margin-left: 10px">
                <el-button type="primary" size="small" @click="confirmSendFile">发送 / 回车发送</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-btn-wrapper">
          <div class="left-wrapper">
            <div class="icon-item" @click.stop="isEmoji = !isEmoji">
              <el-icon class="el-icon-eleme" />
            </div>
            <div class="icon-item">
              <el-upload
                class="avatar-uploader"
                action="aaa"
                :auto-upload="true"
                :show-file-list="false"
                :before-upload="beforeUpload">
                <el-icon class="el-icon-picture" />
              </el-upload>
            </div>
            <div class="icon-item" @click.stop.stop="isMsg = true"><span class="iconfont iconliaotian"></span></div>
          </div>
          <!-- 表情 -->
          <div class="emoji-box" v-if="isEmoji">
            <div v-for="(item, idx) in faces" :key="idx" style="display: flex;justify-content: center;" @click="emojiClick(item.key)">
              <el-image style="width: 30px;height: 30px"
                :src="thumb('/statics/face/'+ item.path)" mode=""></el-image>
            </div>
          </div>
          <div class="textarea-box" style="position:relative;">
            <vue-tribute class="editable" :options="tributeOptions">
              <div ref="editChatCon" id="editChatCon" contenteditable="true" class="editable" autosize @focus="handleFocus" @paste="handlePaste"
                @input="handleInput" @keydown="handleKeyup"/>
            </vue-tribute>
            <!--            <div ref="editable" class="editable" contenteditable="true" @paste="handlePaste"-->
            <!--              @input="handleInput" @keyup="handleKeyup"></div>-->
            <div class="send-btn">
              <el-button class="btns" type="primary" :disabled="disabled" @click.stop="sendText">发送</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="resizeRightBar" class="fenge"/>
    <div class="right-content"
      :style="{width: chatRightWidth + 'px'}"
      v-if="subAccountList.length > 0 && conversation.groupInfo && groupCheckedUsers.length > 0 && showVirtualUser.includes(this.conversation.conversationId)">
      <div class="close"><el-button size="mini" type="danger" @click="hideVirtualUser">关闭</el-button></div>
      <div v-for="(item, idx) in groupCheckedUsers" :key="idx" style="cursor: default">
        <template v-for="(subAccount, subAccountIdx) in subAccountList" :key="subAccountIdx">
          <sub-window v-if="subAccount.user_id === item && subAccount.virtual_type != 3" :conversation="conversation" :account="subAccount" />
        </template>
      </div>
    </div>
    <dialog-history ref="dialogHistory" />
    <dialog-withdraw-log ref="dialogWithdrawLog" />
    <dialog-order-log ref="dialogOrderLog" />
    <dialog-task-level ref="dialogTaskLevel" />
    <dialog-gift ref="dialogGift" @confirm="handleCloseGift" />
    <dialog-group-user ref="dialogGroupUser" />
    <dialog-group-user-mute ref="dialogGroupUserMute" />
    <dialog-create-group ref="dialogCreateGroup" @confirm="handleCreateGroup"/>
    <dialog-edit-group ref="dialogEditGroup" />
    <dialog-retransmission ref="dialogRetransmission" />
    <dialog-profile ref="dialogProfile" />
    <dialog-ip ref="dialogIp" />
    <dialog-send-message ref="dialogSendMessage"></dialog-send-message>

    <el-dialog
      title="修改消息"
      :visible.sync="dialogEditMessageVisible"
      center
      width="400px"
      :close-on-click-modal="false"
      :before-close="handleEditMessageClose">

      <el-input
        type="textarea"
        :rows="4"
        v-model="editMessage.text">
      </el-input>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleEditMessageClose">取 消</el-button>
        <el-button type="primary" @click="handleEditMessageSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import { dateTimeFormat, thumb, fileToBlob, getGift, saveAs, downloadImage, getImageInfo, clearStorage } from '@/utils/utils'
import {
  YeIMUniSDK,
  YeIMUniSDKDefines
} from '@/utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min';
import request from '@/utils/request'
import DialogHistory from "@/views/chat/components/dialog-history";
import DialogWithdrawLog from "@/views/chat/components/dialog-withdraw-log";
import DialogOrderLog from "@/views/chat/components/dialog-order-log";
import DialogTaskLevel from "@/views/chat/components/dialog-task-level";
import DialogGift from "@/views/chat/components/dialog-gift";
import DialogGroupUser from "@/views/chat/components/dialog-group-user";
import DialogGroupUserMute from "@/views/chat/components/dialog-group-user-mute";
import DialogCreateGroup from "@/views/chat/components/dialog-create-group";
import DialogEditGroup from "@/views/chat/components/dialog-edit-group";
import DialogRetransmission from "@/views/chat/components/dialog-retransmission";
import DialogProfile from "@/views/chat/components/dialog-profile";
import DialogIp from "@/views/chat/components/dialog-ip";
import DialogSendMessage from "@/views/manage/components/dialog-send-message";
import SubWindow from "@/views/chat/components/sub-window";
import VueTribute from 'vue-tribute';
import { requestHttp } from "@/api/main";
import $ from 'jquery'
import face from '@/utils/face.js';

export default {
  name: "MsgWindow",
  props: {
    showVirtualUser: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    DialogHistory,
    DialogWithdrawLog,
    DialogOrderLog,
    DialogTaskLevel,
    DialogGift,
    DialogGroupUser,
    DialogGroupUserMute,
    DialogCreateGroup,
    DialogEditGroup,
    DialogRetransmission,
    DialogProfile,
    DialogIp,
    DialogSendMessage,
    VueTribute,
    SubWindow
  },
  data() {
    return {
      faces: face.faces,
      faceObject: face.faceObject,
      scrollSetting: {
        suppressScrollY: true,
        suppressScrollX: false,
        wheelPropagation: false
      },
      lastScrollTop: 0,
      scrollBottom: true,
      scrollTimer: 0,
      chatRightWidth: 300,
      dialogEditMessageVisible: false,
      editMessage: {
        uniqueMessageId: '',
        text: ''
      },
      conversation: {
        conversationId: ''
      },
      userInfo: {
        balance: 0,
        score: 0,
        remarks: '',
        last_other_account: '',
        freeze_task_money: '-',
        freeze_task_commission: '-'
      },
      nextMessageId: '',
      chatHeight: 0,
      isEmoji: false, // 是否显示表情弹框
      chatCon: '', // 输入框输入的聊天内容
      html: '',
      userActive: {}, //左侧用户列表选中信息
      isMsg: false,
      isTransfer: false,
      activeMsg: '', // 选中的话术
      chatList: [],
      text: '',
      limit: 20,
      upperId: 0,
      online: true,//当前客服在线状态
      scrollTop: 0,
      oldHeight: 0,
      isLoad: false,
      isProductBox: false,
      goodsId: "",
      isOrder: false,
      orderId: '',
      upload: '',
      userOnline: {},
      replyMessage: {
        uniqueMessageId: ''
      },
      imageMessage: {
        uniqueMessageId: ''
      },
      showScoreEdit: false,
      showRemarkEdit: false,
      showTranslate: true,
      showMute: true,
      showLoading: false,
      timer: 0,
      clearTimer: 0,
      newMessageTimer: 0,
      groupUsers: [],
      groupCheckedUsers: [],
      tributeOptions: {
        trigger: '@',
        values: [],
        lookup: function (person, mentionText) {
          return person.nickname;
        },
        menuItemTemplate: function (item) {
          return '<img src="'+ thumb(item.original.thumb_avatar) +'" width="30" height="30"/> ' + item.original.nickname2;
        },
        selectTemplate: function(item) {
          return "<span contenteditable=\"false\" data-key=\""+ item.original.user_id +"\" class=\"tribute\">@" + item.original.nickname2 + "</a></span>&nbsp;"
        }
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user,
      subAccountList: state => state.yeim.subAccountList,
      conversationList: state => state.yeim.conversationList
    }),
    disabled() {
      if (this.chatCon.length == 0) {
        return true
      } else {
        return false
      }
    },
    records() {
      return this.chatList.filter((item, index) => {
        if (!this.showMute && item.is_mute == 1) {
          return false;
        }
        return true;
      });
    }
  },
  created() {
    if (this.conversation.name) {
      document.title = this.conversation.name
    } else {
      document.title = '未开始对话'
    }
    const that = this

    //监听新消息
    YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.MESSAGE_RECEIVED, this.onMessage);
    YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.MESSAGE_REVOKED, this.onRevokeMessage);
    YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.PRIVATE_READ_RECEIPT, this.onReceiptMessage);
    YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.MESSAGE_CHANGED, this.onChangedMessage);
    YeIMUniSDK.getInstance().addEventListener(YeIMUniSDKDefines.EVENT.CONNECTED, function () {
      that.getHistoryMessageList()
    });

    this.scrollTimer = setInterval(function() {
      that.setPageScrollTo()
    }, 1000)
  },
  mounted() {
    let that = this
    console.log('enter chat listen')
    window.addEventListener('click', function () {
      that.isEmoji = false
    });
    window.addEventListener('resize', function () {
      that.initHeight()
      that.setPageScrollTo()
    })
  },
  beforeDestroy() {
    //监听新消息
    console.log('exit chat listen')
    const that = this
    YeIMUniSDK.getInstance().removeEventListener(YeIMUniSDKDefines.EVENT.MESSAGE_RECEIVED, this.onMessage);
    YeIMUniSDK.getInstance().removeEventListener(YeIMUniSDKDefines.EVENT.MESSAGE_REVOKED, this.onRevokeMessage);
    YeIMUniSDK.getInstance().removeEventListener(YeIMUniSDKDefines.EVENT.PRIVATE_READ_RECEIPT, this.onReceiptMessage);
    YeIMUniSDK.getInstance().removeEventListener(YeIMUniSDKDefines.EVENT.MESSAGE_CHANGED, this.onChangedMessage);
    YeIMUniSDK.getInstance().removeEventListener(YeIMUniSDKDefines.EVENT.CONNECTED, function () {
      that.getHistoryMessageList()
    });
    if (this.scrollTimer > 0) {
      clearInterval(this.scrollTimer)
    }
  },
  methods: {
    thumb,
    dateTimeFormat,
    getGift,
    test(event) {
      console.log(event)
      return false
    },
    hideVirtualUser() {
      this.$emit('hide-virtual-user', this.conversation.conversationId)
    },
    initHeight() {
      this.chatHeight = window.innerHeight - document.getElementById('chat_title').offsetHeight - 70;
    },
    initGG(item) {
      const that = this
      this.conversation = item
      this.tributeOptions.values = [];
      if (item.type == 'private') {
        document.title = this.user.userId + ' - 私聊（' + this.conversation.userInfo.nickname + '）'
      } else {
        document.title = this.user.userId + ' - 群聊（' + this.conversation.groupInfo.name + '）'
      }
      that.initHeight()

      $('#editChatCon').focus()

      this.isLoad = true
      that.showRemarkEdit = false
      // that.showTranslate = true
      that.showMute = true
      that.userInfo = {
        balance: 0,
        score: 0,
        remarks: '',
        last_other_account: ''
      },
        // 进入页面加载消息记录
        this.$refs.editChatCon.innerHTML = ''
      this.scrollBottom = true
      this.chatList = JSON.parse(JSON.stringify([]))
      this.$forceUpdate()
      this.nextMessageId = ''
      this.replyMessage = {
        uniqueMessageId: ''
      }
      that.getUserInfoFromCache()
      that.getGroupUsersFromCache()
      this.getHistoryMessageListFromCache();

      that.initHeight()
      setTimeout(function() {
        that.initHeight()
      }, 500)
      // 加载用户信息等，延迟操作
      if (this.timer > 0) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(function () {
        that.initHeight()
        that.getHistoryMessageList()
        //清除会话未读数
        that.handleFocus()
        that.getUserInfo()
        that.getGroupUsers()
      }, 1000)

      if (this.newMessageTimer > 0) {
        clearInterval(this.newMessageTimer)
      }
      // this.newMessageTimer = setInterval(function (){
      //   if (that.scrollBottom) {
      //     that.getHistoryMessageList(null)
      //   }
      // }, 10000)

      this.resizeRight();
    },
    onMessage(e) {
      // console.log("收到新消息")
      const that = this
      let message = e;
      this.insertMessage(message);
    },
    onRevokeMessage(message) {
      try {
        console.log('revoke', message)
        let index = this.chatList.findIndex(item => item.messageId === message.messageId)
        if (index !== -1) {
          this.chatList.splice(index, 1);
          this.$forceUpdate()
        }
      } catch (e) {
        console.log(e)
      }
    },
    onReceiptMessage(message) {
      try {
        console.log('receipt', message)
        let index = this.chatList.findIndex(item => item.uniqueMessageId === message.uniqueMessageId)
        console.log(index)
        if (index !== -1) {
          this.chatList[index].readStatus = message.readStatus
          this.$forceUpdate()
        }
      } catch (e) {
        console.log(e)
      }
    },
    onChangedMessage(message) {
      try {
        let index = this.chatList.findIndex(item => item.uniqueMessageId === message.uniqueMessageId)
        if (index !== -1) {
          this.chatList[index].body = message.body
          this.chatList[index].body_translate = message.body_translate
          this.$forceUpdate()
        }
      } catch (e) {
        console.log(e)
      }
    },
    newConversation(type, toId) {
      const that = this
      requestHttp('agent/new_conversation', {
        'type': type,
        'to_id': toId,
        'is_line': 1
      }).then((res) => {
        if (res.code == 200) {
          // 重新加载好友等数据
          that.$store.state.conversationList = []
          that.$store.state.friendList = []
          that.$store.state.friendApplyUnread = []
          that.$store.state.friendApplyList = []

          YeIMUniSDK.getInstance().reload();

          that.$emit('newConversation', res.data)
        } else {
          that.$message.error({ message: res.message, offset: 100 })
        }
      })
    },
    handleScroll(e) {
      if (!this.isLoad && e.target.scrollTop == 0) {
        this.isLoad = true
        this.getHistoryMessageList(this.nextMessageId)
      }
      var container = document.querySelector("#chat_scroll_content");
      if (container) {
        // console.log(e.target.scrollTop, this.chatHeight, container.offsetHeight)
        if ((e.target.scrollTop + this.chatHeight + 200) >= container.offsetHeight) {
          if (!this.scrollBottom) {
            console.log('恢复为滚动')
            this.scrollBottom = true
            this.handleFocus()
          }
        } else {
          if (this.scrollBottom && this.lastScrollTop - e.target.scrollTop > 0) {
            console.log('禁止滚动')
            this.scrollBottom = false
          }
        }
        this.lastScrollTop = e.target.scrollTop
      }
    },
    handleAddTribute(userId, nickname) {
      if (userId == this.conversation.userId) {
        return;
      }
      this.$refs.editChatCon.innerHTML += "<span contenteditable=\"false\" data-key=\""+ userId +"\" class=\"tribute\">@" + nickname + "</a></span>&nbsp;"
      this.handleInput()
    },
    handleDropdown(e, item, mute) {
      // console.log(e)
      const that = this
      switch (e) {
        case 'add':
          break;
        case 'new-conversation':
          this.newConversation('private', item.from)
          break;
        case 'kick':
          this.$confirm('确定踢出？', '提示').then(function () {
            requestHttp('group/kick', {
              'group_id': that.conversation.conversationId,
              'user_id': item.from
            }).then((res) => {
              if (res.code == 200) {
                that.$message.success({ message: res.message, offset: 100 })
                YeIMUniSDK.getInstance().reload();
              } else {
                that.$message.error({ message: res.message, offset: 100 })
              }
            })
          })
          break;
        case 'switch-mute':
          this.$confirm(mute == 1 ? '禁言该用户？' : '确定解除禁言', '提示').then(function () {
            requestHttp('agent/switch_mute', {
              'user_id': item.from,
              'is_mute': mute
            }).then((res) => {
              if (res.code == 200) {
                that.$message.success({ message: res.message, offset: 100 })
              } else {
                that.$message.error({ message: res.message, offset: 100 })
              }
            })
          })
          break;
        case 'revocation':
          YeIMUniSDK.getInstance().revokeMessage({
            message: item,
            success: (res) => {
              that.chatList.forEach((chat, idx) => {
                if (chat.messageId == item.messageId) {
                  that.chatList.splice(idx, 1)
                }
              })
              that.$forceUpdate()
            },
            fail: (res) => {
              that.$message.error({ message: res.message, offset: 100 })
            }
          })
          break;
        case 'revocation-mute':
          console.log(item)
          requestHttp('agent/switch_mute', {
            'user_id': item.from,
            'is_mute': 1
          }).then((res) => {
            if (res.code == 200) {
              that.$message.success({ message: res.message, offset: 100 })
              YeIMUniSDK.getInstance().revokeMessage({
                message: item,
                success: (res) => {
                  that.chatList.forEach((chat, idx) => {
                    if (chat.messageId == item.messageId) {
                      that.chatList.splice(idx, 1)
                    }
                  })
                  that.$forceUpdate()
                },
                fail: (res) => {
                  that.$message.error({ message: res.message, offset: 100 })
                }
              })
            } else {
              that.$message.error({ message: res.message, offset: 100 })
            }
          })
          break;
        case 'save-as':
          saveAs(thumb(item.body.originalUrl))
          break;
        case 'copy':
          this.copyHandle(thumb(item.body.originalUrl))
          break;
        case 'retransmission':
          that.showRetransmission(item.messageId)
          break;
        case 'edit':
          that.dialogEditMessageVisible = true
          that.editMessage = {
            uniqueMessageId: item.uniqueMessageId,
            text: item.body.text
          }
          break;
        case 'reply':
          that.replyMessage = item
          break;
        case 'cancel_reply':
          that.replyMessage = {
            uniqueMessageId: ''
          }
          break;
        case 'cancel_send':
          that.imageMessage = {
            uniqueMessageId: ''
          }
          break;
      }
    },
    onUserContextmenu(event, item) {
      const that = this
      let items = []

      if (this.conversation.type == 'group') {
        items.push({
          label: "发起私聊",
          onClick: () => {
            that.handleDropdown('new-conversation', item)
          }
        })
        items.push({
          label: "踢出群组",
          onClick: () => {
            that.handleDropdown('kick', item)
          }
        })
        if (this.conversation.is_main == 1 || this.conversation.is_vice == 1) {
          items.push({
            label: "禁言",
            onClick: () => {
              that.handleDropdown('switch-mute', item, 1)
            }
          })
          items.push({
            label: "解除禁言",
            onClick: () => {
              that.handleDropdown('switch-mute', item, 0)
            }
          })
        }
      }
      if (items.length > 0) {
        this.$contextmenu({
          items: items,
          event, // 鼠标事件信息
          customClass: "custom-class", // 自定义菜单 class
          zIndex: 3, // 菜单样式 z-index
          minWidth: 80 // 主菜单最小宽度
        });
      }
      return false;
    },
    onContextmenu(event, item) {
      const that = this
      let items = []
      items.push({
        label: "回复",
        onClick: () => {
          that.handleDropdown('reply', item)
        }
      })
      items.push({
        label: "撤回",
        onClick: () => {
          that.handleDropdown('revocation', item)
        }
      })
      items.push({
        label: "撤回并禁言",
        onClick: () => {
          that.handleDropdown('revocation-mute', item)
        }
      })
      if (item.from == this.user.userId) {
        // if (item.time > (new Date().getTime() - 300000)) {
        //   items.push({
        //     label: "撤回",
        //     onClick: () => {
        //       that.handleDropdown('revocation', item)
        //     }
        //   })
        // }
      }
      if (item.type == 'image') {
        items.push({
          label: "另存为",
          onClick: () => {
            that.handleDropdown('save-as', item)
          }
        })
        items.push({
          label: "复制",
          onClick: () => {
            that.handleDropdown('copy', item)
          }
        })
      }
      items.push({
        label: "转发",
        onClick: () => {
          that.handleDropdown('retransmission', item)
        }
      })
      if (item.type == 'text') {
        if (this.conversation.type == 'private' || (this.conversation.is_main != 1 && this.conversation.is_vice != 1)) {
          items.push({
            label: "编辑",
            onClick: () => {
              that.handleDropdown('edit', item)
            }
          })
        }
      }
      this.$contextmenu({
        items: items,
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 3, // 菜单样式 z-index
        minWidth: 80 // 主菜单最小宽度
      });
      return false;
    },
    showHistory() {
      this.$refs.dialogHistory.toggle(this.user.userId, this.conversation, this.showTranslate)
    },
    showIp() {
      this.$refs.dialogIp.toggle(this.userInfo.user_id)
    },
    showSendMessage() {
      this.$refs.dialogSendMessage.toggle(this.userInfo.user_id)
    },
    showWithdrawLog() {
      this.$refs.dialogWithdrawLog.toggle(this.userInfo.user_id)
    },
    showOrderLog(userId) {
      if (userId == 'aaa') {
        this.groupUsers.forEach((item) => {
          if (item.user_id != this.user.userId && item.virtual_type == 1 && item.can_tribute == 1) {
            userId = item.user_id
          }
        })
      } else {
        userId = this.userInfo.user_id
      }
      this.$refs.dialogOrderLog.toggle(userId)
    },
    showTaskLevel(userId) {
      if (userId == 'aaa') {
        this.groupUsers.forEach((item) => {
          if (item.user_id != this.user.userId && item.virtual_type == 1 && item.can_tribute == 1) {
            userId = item.user_id
          }
        })
      } else {
        userId = this.userInfo.user_id
      }
      this.$refs.dialogTaskLevel.toggle(userId)
    },
    showCreateGroup() {
      this.$refs.dialogCreateGroup.toggle(this.conversation.friend_user_id)
    },
    showEditGroup() {
      this.$refs.dialogEditGroup.toggle(this.conversation)
    },
    showGift(userId, messageId) {
      userId = userId || this.userInfo.user_id
      this.$refs.dialogGift.toggle(userId, messageId)
    },
    switchGiftLevel(userId, level) {
      const that = this
      this.showLoading = true
      this.$confirm(level == 1 ? '切换为初级，确定吗？' : '切换为初级，确定吗？',  '提示').then(function () {
        requestHttp('agent/switch_gift_level', {
          'user_id': userId,
          'gift_level': level
        }).then((res) => {
          that.showLoading = false
          if (res.code == 200) {
            that.$message.success({message: res.message, offset: 100})
            that.chatList.forEach((item) => {
              if (item.from == userId) {
                item.fromUserInfo.giftLevel = level
              }
            })
            that.getHistoryMessageList()
          } else {
            that.$message.error({message: res.message, offset: 100})
          }
        })
      })
    },
    handleCloseGift(data) {
      this.chatList.forEach((item) => {
        if (item.uniqueMessageId == data.message_id) {
          item.gift = data.gift
        }
      })
      this.$forceUpdate()
    },
    showGroupUser(groupId) {
      groupId = groupId || this.conversation.conversationId
      this.$refs.dialogGroupUser.toggle(groupId)
    },
    showGroupUserMute() {
      this.$refs.dialogGroupUserMute.toggle(this.conversation.conversationId)
    },
    handleCreateGroup(data) {
      console.log("建群成功", data)
      this.newConversation('group', data.group_id)
    },
    showRetransmission(messageId) {
      this.$refs.dialogRetransmission.toggle(messageId)
    },
    showProfile() {
      this.$refs.dialogProfile.toggle(this.conversation)
    },
    insertMessage(message) {
      const that = this
      try {
        if (message.conversationId === this.conversation.conversationId) {
          let index = this.chatList.findIndex(item => item.messageId === message.messageId)
          if (index === -1) {
            this.chatList.push(message);
          } else {
            this.chatList[index] = message
            this.chatList = JSON.parse(JSON.stringify(this.chatList))
          }
          this.$forceUpdate()
          this.setPageScrollTo();
          if (that.clearTimer > 0) {
            clearTimeout(that.clearTimer)
          }
          if (that.scrollBottom) {
            that.clearTimer = setTimeout(function () {
              YeIMUniSDK.getInstance().clearConversationUnread(that.conversation.conversationId);
            }, 1000);
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getUserInfoFromCache() {
      if (this.conversation.type == 'group') {
        return;
      }
      if (localStorage.getItem('yeim:friend_user_info_' + this.conversation.friend_user_id)) {
        this.userInfo = JSON.parse(localStorage.getItem('yeim:friend_user_info_' + this.conversation.friend_user_id))
      }
    },
    getUserInfo(show) {
      if (this.conversation.type == 'group') {
        return;
      }
      const that = this
      if (show) {
        that.showLoading = this.$loading({
          lock: true,
          text: '正在获取余额',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      requestHttp('agent/user_info', { user_id: this.conversation.friend_user_id }).then((response) => {
        that.userInfo = response.data
        localStorage.setItem('yeim:friend_user_info_' + this.conversation.friend_user_id, JSON.stringify(that.userInfo))
        if (show) {
          that.showLoading.close();
        }
      })
    },
    getGroupUsersFromCache() {
      if (this.conversation.type == 'private') {
        return;
      }
      this.tributeOptions.values = [];
      this.groupUsers = []
      if (localStorage.getItem('yeim:group_user_' + this.conversation.conversationId)) {
        this.tributeOptions.values = JSON.parse(localStorage.getItem('yeim:group_user_' + this.conversation.conversationId))
        this.tributeOptions.values = this.tributeOptions.values.filter((item) => {
          return item.can_tribute == 1
        })
        this.groupUsers = JSON.parse(localStorage.getItem('yeim:group_user_' + this.conversation.conversationId))
        this.groupCheckedUsers = JSON.parse(localStorage.getItem('yeim:group_checked_user_' + this.conversation.conversationId))
      }
    },
    getGroupUsers() {
      const that = this
      if (this.conversation.type == 'group') {
        requestHttp('agent/group_users', { group_id: this.conversation.conversationId }).then((response) => {
          that.tributeOptions.values = response.data.datas.filter((item) => {
            return item.can_tribute == 1
          })
          that.groupUsers = response.data.datas
          that.groupCheckedUsers = response.data.values
          localStorage.setItem('yeim:group_user_' + this.conversation.conversationId, JSON.stringify(response.data.datas))
          localStorage.setItem('yeim:group_checked_user_' + this.conversation.conversationId, JSON.stringify(response.data.values))
        })
      }
    },
    getHistoryMessageListFromCache() {
      const that = this
      YeIMUniSDK.getInstance().getHistoryMessageList({
        nextMessageId: this.nextMessageId,
        conversationId: this.conversation.conversationId,
        fromCache: true,
        success: (res) => {
          that.isLoad = false
          console.log('聊天记录', res);
          if (this.chatList.length <= 0) {
            this.chatList = res.data.list;
            this.$forceUpdate()
            this.setPageScrollTo();
          } else {
            this.chatList.unshift(...res.data.list);
          }
          that.$forceUpdate()
        },
        fail: (err) => {
          console.log(err);
          this.$message.error({ message: err.message, offset: 100 })
        }
      });
    },
    getHistoryMessageList(next) {
      const that = this
      if (!this.conversation || !this.conversation.conversationId || this.conversation.conversationId == '') {
        return
      }
      YeIMUniSDK.getInstance().getHistoryMessageList({
        nextMessageId: next,
        conversationId: this.conversation.conversationId,
        success: (res) => {
          that.isLoad = false
          console.log('聊天记录', res);

          if (res.data.list.length == 0 || res.data.list[0].conversationId != this.conversation.conversationId) {
            return;
          }

          if (this.chatList.length <= 0 || next == '' || !next) {
            this.chatList = res.data.list;
            this.setPageScrollTo();
            setTimeout(function () {
              that.setPageScrollTo(true)
            }, 1000)
          } else {
            console.log('a')
            this.chatList = [...res.data.list, ...this.chatList];
          }
          that.$forceUpdate()

          if (this.chatList.length > 0) {
            this.nextMessageId = this.chatList[0].messageId;
          }
        },
        fail: (err) => {
          console.log(err);
          this.$message.error({ message: err.message, offset: 100 })
        }
      });
    },

    changeDivInnerHTML(el, value) {
      // 修改innerHTML，光标会自行移动到开头
      el.innerHTML = value;
      // var range = document.createRange();
      // range.selectNodeContents(el);
      // range.collapse(false);
      // var sel = window.getSelection();
      // sel.removeAllRanges();
      // sel.addRange(range);
    },

    handleFocus() {
      const that =this
      this.conversationList.forEach((item, idx) => {
        if (that.conversation.conversationId == item.conversationId && item.unread > 0) {
          YeIMUniSDK.getInstance().clearConversationUnread(that.conversation.conversationId);
        }
      })
    },

    handleInput() {
      let reg = /^&nbsp;+/g;
      if (reg.test(this.$refs.editChatCon.innerHTML)) {
        this.changeDivInnerHTML(this.$refs.editChatCon, this.$refs.editChatCon.innerHTML.replace(/^&nbsp;+/g,""))
      }
      this.chatCon = this.$refs.editChatCon.innerHTML.replace(/^&nbsp;+/g,"");
    },

    handleKeyup($event) {
      const that = this
      var keycode = window.event ? $event.keyCode : $event.which;
      var evt = $event || window.event;

      //回车-->发送消息
      if (keycode == 13 && !(evt.ctrlKey)) {
        $event.preventDefault();
        if (that.imageMessage.uniqueMessageId != '') {
          this.confirmSendFile()
          return false;
        }
        this.sendText();
        return false;
      }

      //ctrl+回车-->换行
      if (evt.ctrlKey && evt.keyCode == 13) {
        $event.preventDefault();
        $('#editChatCon').append('<br/><br/>')
        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
        that.handleInput()
        return false;
      }
    },

    handlePaste(event) {
      console.log('paste: ')
      const that = this
      event.preventDefault();
      let clipboardDataItem = event.clipboardData.items[0];
      if (clipboardDataItem.type.indexOf('image/') != -1) {
        let file = clipboardDataItem.getAsFile();
        this.sendFile(file)
        this.$refs.editChatCon.innerText = '';
        this.chatCon = ''
      } else if (clipboardDataItem.type.indexOf('text/') != -1) {
        clipboardDataItem.getAsString(function(res) {
          let selection = document.getSelection()
          // 光标对象
          let range = selection.getRangeAt(0)
          range.deleteContents()
          let objE = document.createElement("span");
          objE.innerHTML = res.replaceAll('\n', '<br>')
          range.insertNode(objE)
          // document.execCommand('selectAll', false, null);
          document.getSelection().collapseToEnd();
          that.handleInput()
        })
      }
    },


    // 转dom对象
    parseDom(arg) {
      var objE = document.createElement("span");
      objE.innerHTML = arg;
      return objE.childNodes;
    },

    handleFormatError(file) {
      this.$message.error("上传图片只能是 jpg、jpg、jpeg、gif 格式!");
    },

    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
      }

      this.sendFile(file)

      return false;
    },
    sendFile(file) {
      const that = this
      getImageInfo(file, function (imageInfo){
        fileToBlob(file, function (res) {
          let message = YeIMUniSDK.getInstance().createImageMessage({
            toId: that.conversation.conversationId,
            conversationType: that.conversation.type,
            body: {
              file: {
                tempFilePath: res,
                width: imageInfo.width,
                height: imageInfo.height
              }
            }
          });
          if (message.code) {
            that.$message.error(message.message)
            return
          }
          if (that.replyMessage.uniqueMessageId !== '') {
            message['replyMessageId'] = that.replyMessage.uniqueMessageId
            message['replyMessage'] = that.replyMessage
          }
          that.imageMessage = message

          $('#editChatCon').focus()

          if (that.user.send_file_action == 1) {
            that.confirmSendFile()
          }
        })
      })
    },
    confirmSendFile()
    {
      const that = this
      that.insertMessage(that.imageMessage)
      that.$refs.editChatCon.innerText = '';
      YeIMUniSDK.getInstance().sendMessage({
        message: that.imageMessage,
        success: (res) => {
          that.handleDropdown('cancel_reply', {})
          that.handleDropdown('cancel_send', {})
          that.insertMessage(res.data);
          that.setPageScrollTo();
        },
        fail: (err) => {
          that.chatList.forEach((chat, idx) => {
            if (chat.messageId == that.imageMessage.messageId) {
              that.chatList[idx].isSend = 2
            }
          })
          // 发送失败
          if (err.code == 10003) {
            clearStorage()
            that.$router.push({ name: 'UserLogin' });
            return;
          }
          if (err.code == 400) {
            that.$message.error(err.message)
            return;
          }
        }
      });
      this.handleFocus()
    },
    // 输入框选择表情
    emojiClick(data) {
      let val = `[face_${data}]`
      // let selection = document.getSelection()
      // // 光标对象
      // let range = selection.getRangeAt(0)
      // let objE = document.createElement("span");
      // objE.innerHTML = val
      // range.insertNode(objE)
      // // document.execCommand('selectAll', false, null);
      // document.getSelection().collapseToEnd();
      // this.handleInput()
      this.$refs.editChatCon.innerHTML += val
      this.chatCon += val;
      this.isEmoji = false
    },
    parseText(text) {
      var regex = /\[face_(.+?)\]/g;
      let emojis_char = text.match(regex);
      if (emojis_char) {
        for (let i = 0; i < emojis_char.length; i++) {
          let char = emojis_char[i];
          char = char.replace("[", "");
          char = char.replace("]", "");
          let index = char.replace('face_', '');
          if (face.faceObject[index] && face.faceObject[index].path) {
            let image = "<img style='width:20px;height:20px' src='" + thumb('/statics/face/' + this.faceObject[index].path) + "' />";
            text = text.replace(emojis_char[i], image);
          }
        }
      }
      return text.replace(/[\r\n]/g, '<br>');
    },
    // 文本发送
    sendText() {
      this.handleInput()
      if (!this.chatCon) {
        this.$refs.editChatCon.innerHTML = ''
        return this.$message.error('请输入内容');
      }

      const that = this
      let message = YeIMUniSDK.getInstance().createTextMessage({
        toId: this.conversation.conversationId,
        conversationType: this.conversation.type,
        body: {
          text: this.chatCon
        },
        extra: ""
      });
      if (that.replyMessage.uniqueMessageId !== '') {
        message['replyMessageId'] = that.replyMessage.uniqueMessageId
        message['replyMessage'] = that.replyMessage
      }

      that.$refs.editChatCon.innerHTML = ''
      that.chatCon = ''
      this.insertMessage(message);
      that.handleDropdown('cancel_reply', {})
      this.setPageScrollTo();

      YeIMUniSDK.getInstance().sendMessage({
        message: message,
        success: (res) => {
          this.insertMessage(res.data);
          this.setPageScrollTo();
        },
        fail: (err) => {
          console.log(err)
          that.chatList.forEach((chat, idx) => {
            if (chat.messageId == message.messageId) {
              that.chatList[idx].isSend = 2
            }
          })
          // 发送失败
          if (err.code == 10003) {
            clearStorage()
            that.$router.push({ name: 'UserLogin' });
            return;
          }
          if (err.code == 400) {
            that.$message.error(err.message)
            return;
          }
        }
      });
      this.handleFocus()
    },
    // 设置页面滚动位置
    setPageScrollTo(force) {
      if (!this.scrollBottom && !force) {
        return
      }
      var container = document.querySelector("#chat_scroll_content");
      if (container) {
        this.scrollTop = container.offsetHeight + 0.01
        document.querySelector("#chat_scroll").scrollTop = this.scrollTop
      }
    },
    handleSwitchTradeStatus(status) {
      const typeArr = {
        1: 'primary',
        2: 'danger',
        3: 'success',
        4: 'warning',
        5: 'info',
      }
      const statusArr = {
        1: '正常',
        2: '禁止交易',
        3: '盈利',
        4: '亏损',
        5: '其他',
      }
      const that = this
      this.$confirm('确认设置交易状态为' + statusArr[status] + '？', '提示').then(function () {
        const oldType = that.userInfo.trade_status_type
        const oldStr = that.userInfo.trade_status_str
        that.userInfo.trade_status_type = typeArr[status]
        that.userInfo.trade_status_str = statusArr[status]
        requestHttp('agent/switch_trade_status', {
          'user_id': that.userInfo.last_other_account,
          'trade_status': status
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem('yeim:friend_user_info_' + that.conversation.friend_user_id, JSON.stringify(that.userInfo))
            that.$message.success({ message: res.message, offset: 100 })
            that.getUserInfo()
          } else {
            that.userInfo.trade_status_type = oldType
            that.userInfo.trade_status_str = oldStr
            that.getUserInfo()
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    handleSwitchWithdrawStatus(status) {
      const statusArr = {
        1: '恢复出金',
        2: '冻结出金',
        3: '稽查中'
      }
      const that = this
      this.$confirm('确认设置出金状态为' + statusArr[status] + '？', '提示').then(function () {
        const old = that.userInfo.withdraw_status
        that.userInfo.withdraw_status = status
        requestHttp('agent/switch_withdraw_status', {
          'user_id': that.userInfo.last_other_account,
          'trade_status': status
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem('yeim:friend_user_info_' + that.conversation.friend_user_id, JSON.stringify(that.userInfo))
            that.$message.success({ message: res.message, offset: 100 })
            that.getUserInfo()
            that.$store.state.yeim.conversationList.forEach((item) => {
              if (item.conversationId == that.conversation.conversationId) {
                item.withdraw_status = status
              }
            })
          } else {
            that.userInfo.withdraw_status = old
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    handleSendGift(userId, value, messageId) {
      const that = this
      that.chatList.forEach((item) => {
        console.log(item)
        if (item.uniqueMessageId == messageId) {
          item.gift = value
        }
      })
      that.$forceUpdate()
      requestHttp('agent/send_gift', {
        'user_id': userId,
        'gift': value,
        'message_id': messageId
      }).then((res) => {
        if (res.code == 200) {
          // that.$message.success({message: res.message, offset: 100})
        } else {
          that.chatList.forEach((item) => {
            if (item.uniqueMessageId == messageId) {
              item.gift = 0
            }
          })
          that.$forceUpdate()
          that.$message.error({ message: res.message, offset: 100 })
        }
      })
    },
    handleRevocationGift(messageId, gift) {
      const that = this
      this.$confirm('确认撤回此笔彩金，金额为：' + gift + '？', '提示').then(function () {
        that.chatList.forEach((item) => {
          if (item.uniqueMessageId == messageId) {
            item.gift = 0
          }
        })
        that.$forceUpdate()
        requestHttp('agent/revocation_gift', {
          'gift': gift,
          'message_id': messageId
        }).then((res) => {
          if (res.code == 200) {
          } else {
            that.chatList.forEach((item) => {
              console.log(item)
              if (item.uniqueMessageId == messageId) {
                item.gift = gift
              }
            })
            that.$forceUpdate()
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    handleSwitchMute(isMute) {
      const isMuteArr = {
        1: '禁言',
        0: '取消禁言'
      }
      const that = this
      this.$confirm('确认设置该群组为' + isMuteArr[isMute] + '？', '提示').then(function () {
        that.conversation.groupInfo.is_mute = isMute
        requestHttp('group/switch_mute', {
          'group_id': that.conversation.conversationId,
          'is_mute': isMute
        }).then((res) => {
          if (res.code == 200) {
            that.$emit('changeField', {'conversationId': that.conversation.conversationId, 'field': 'is_mute', 'value': isMute})
            that.$message.success({ message: res.message, offset: 100 })
          } else {
            that.conversation.groupInfo.is_mute = isMute == 0 ? 1 : 0
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    },
    handleScoreEdit() {
      const that = this
      requestHttp('agent/save_score', {
        'user_id': that.userInfo.last_other_account,
        'score': that.userInfo.score
      }).then((res) => {
        if (res.code == 200) {
          that.getUserInfo()
          that.$message.success({ message: res.message, offset: 100 })
          that.showScoreEdit = false
        } else {
          that.$message.error({ message: res.message, offset: 100 })
        }
      })
      // this.$prompt('请输入信誉分', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   inputValue: that.userInfo.score
      // }).then(({ value }) => {
      //   requestHttp('agent/save_score', {
      //     'user_id': that.userInfo.last_other_account,
      //     'score': value
      //   }).then((res) => {
      //     that.userInfo.score = value
      //     if (res.code == 200) {
      //       that.$message.success({ message: res.message, offset: 100 })
      //       that.showScoreEdit = false
      //     } else {
      //       that.$message.error({ message: res.message, offset: 100 })
      //     }
      //   })
      // })
    },
    handleRemarkEdit() {
      const that = this
      requestHttp('agent/save_remark', {
        'user_id': that.userInfo.user_id,
        'remarks': that.userInfo.remarks
      }).then((res) => {
        if (res.code == 200) {
          that.$store.state.yeim.conversationList.forEach((item) => {
            if (item.conversationId == that.conversation.conversationId) {
              item.remarks = that.userInfo.remarks
            }
          })
          localStorage.setItem('yeim:friend_user_info_' + that.conversation.friend_user_id, JSON.stringify(that.userInfo))
          that.getUserInfo()
          that.$message.success({ message: res.message, offset: 100 })
          that.showRemarkEdit = false
        } else {
          that.$message.error({ message: res.message, offset: 100 })
        }
      })
      // this.$prompt('请输入备注', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   inputValue: that.userInfo.remarks
      // }).then(({ value }) => {
      //   requestHttp('agent/save_remark', {
      //     'user_id': that.userInfo.user_id,
      //     'remarks': value
      //   }).then((res) => {
      //     that.userInfo.remarks = value
      //     if (res.code == 200) {
      //       that.$message.success({ message: res.message, offset: 100 })
      //       that.showRemarkEdit = false
      //     } else {
      //       that.$message.error({ message: res.message, offset: 100 })
      //     }
      //   })
      // })
    },
    handleEditMessageClose() {
      this.dialogEditMessageVisible = false
    },
    handleEditMessageSubmit() {
      const that = this
      requestHttp('agent/save_message', this.editMessage).then((res) => {
        if (res.code == 200) {
          that.$message.success({ message: res.message, offset: 100 })
          that.dialogEditMessageVisible = false
        } else {
          that.$message.error({ message: res.message, offset: 100 })
        }
      })
    },
    copyHandle(url) {
      let canvas = document.createElement('canvas') // 创建一个画板
      let image = new Image()
      image.setAttribute('crossOrigin', 'Anonymous') //可能会有跨越问题
      image.src = url
      image.onload = () => {
        // img加载完成事件
        canvas.width = image.width //设置画板宽度
        canvas.height = image.height //设置画板高度
        canvas.getContext('2d').drawImage(image, 0, 0) //加载img到画板
        let url = canvas.toDataURL('image/png') // 转换图片为dataURL，格式为png
        this.clipboardImg(url) // 调用复制方法
      }
    },

    // 从url下载并复制图片，如果是png格式的图片直接调用此方法就能复制成功
    async clipboardImg(url) {
      try {
        const data = await fetch(url)
        const blob = await data.blob()
        await navigator.clipboard.write([
          new window.ClipboardItem({
            [blob.type]: blob,
          }),
        ])
        this.$message.success({ message: '复制成功', offset: 100 })
      } catch (err) {
        this.$message.success({ message: '复制失败', offset: 100 })
      }
    },
    resizeRight() {
      const that = this
      console.log(localStorage.getItem('chat-right-width'))
      if (localStorage.getItem('chat-right-width')) {
        this.chatRightWidth = localStorage.getItem('chat-right-width')
      }
      this.$refs.resizeRightBar.onmousedown = e => {
        // left初始宽度
        const oldWidth = $('.right-content').width()
        // 点击鼠标初始X值
        const startX = e.clientX
        document.onmousemove = e => {
          // 当前鼠标X值
          const endX = e.clientX
          // 当前鼠标在X轴移动的距离
          const distance = startX - endX
          // left-top当前高度
          const newWidth = oldWidth + distance
          // 设置dom样式
          that.chatRightWidth = newWidth
        }
        document.onmouseup = () => {
          localStorage.setItem('chat-right-width', that.chatRightWidth)
          document.onmousemove = null;
          document.onmouseup = null;
        }
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
textarea.ivu-input {
  border: none;
  resize: none;
}

.content-wrapper {
  margin: 0 auto;
  background: #fff;
  display: flex;

  .chat-content {
    flex: 1;
    height: 100%;
    border-right: 1px solid #ECECEC;
    position: relative;
    padding-bottom: 55px;

    .top-buttons {
      padding: 0px 0 0px 0;
      text-align: left;
      //padding-left: 30px;
      .el-button {
        margin-bottom: 5px !important;
      }
    }

    .chat-title {
      background: #f8f8f8;
      padding: 10px 10px;
      line-height: 20px;
      display: flex;
      justify-content: left;
      text-align: left;

      .el-image {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 4px;
      }

      .des {
        width: 100%;

        &.group {
          //display: flex;
          //justify-content: space-between;
          line-height: 30px;
        }
      }
    }

    .chat-body {
      flex: 1;
      .chat-scroll::-webkit-scrollbar {
        width: 5px; /*  设置纵轴（y轴）轴滚动条 */
        height: 0px; /*  设置横轴（x轴）轴滚动条 */
      }
      //.chat-scroll:hover::-webkit-scrollbar {
      //  width: 10px;
      //}
      .chat-item {
        margin-bottom: 10px;
        min-height: 70px;
        &.system {
          min-height: 30px;
          text-align: center;
        }

        .flex-box {
          display: flex;
        }

        .avatar {
          width: 40px;
          height: 40px;
          margin-right: 16px;

          .el-image {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }

          .remark-nickname {
            padding-top: 5px;
            font-size: 12px;
            //color: red;
          }
        }

        .msg-content {
          text-align: left;
          max-width: 80%;
          .name {
            text-align: left;
            padding-bottom: 5px;
            font-weight: bold;
            font-size: 12px;
          }

          .time {
            color: #9ea3ac;
            margin: 3px;
            word-break: keep-all;
            white-space: nowrap;
            font-size: 12px;
          }

          .read {
            margin: 0px 0 0 0;
            text-align: right;
            color: #bbb;
            font-size: 12px;

            .blue {
              color: blue;
            }
            .red {
              color: red;
            }
          }

          .mute {
            margin-left: 10px;
            margin-top: 5px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border: 1px solid #0e73e8;
            background: #0e73e8;
            color: #fff;
            text-align: center;

            &.active {
              border: 1px solid red;
              background: red;
              color: #fff;
            }
          }
        }

        .msg-wrapper {
          min-width: 40px;
          max-width: 500px;
          color: #000000;
          font-size: 14px;
          overflow: hidden;
          text-align: left;
          display: inline-block;

          .txt-wrapper {
            min-width: 80px;
            word-break: break-all;
            background: #F5F5F5;
            border-radius: 4px;
            font-size: 14px;
            padding: 8px 8px;
            &.blue {
              border-radius: 0 0 4px 4px;
              background: #ddd;
              color: blue !important;
            }
          }

          .pad16 {
            //padding: 3px 8px;
            .transfer {
              padding-top: 0px
            }
          }

          .img-wraper {
            font-size: 0px !important;
            background: #F5F5F5;
            border-radius: 4px;
            display: inline-block;
          }

          .img-wraper .el-image, .img-wraper img {
            max-width: 100%;
            height: auto;
            display: block;
            border-radius: 4px;
          }

          .method {
            text-align: center;
            background: #ddd;
            border-radius: 4px;
            padding: 0px 5px;
            &.gift {
              line-height: 30px;
              background: rgba(0, 0, 0, 0.3);
              display: flex;
              justify-content: space-between;
              padding: 0 30px;
            }
          }
        }

        &.right-box {
          .flex-box {
            flex-direction: row-reverse;

            .avatar {
              margin-right: 0;
              margin-left: 16px;
            }
            .msg-content{
              text-align: right;
              .name {
                text-align: right;
              }
              .msg-wrapper {
                //background: #CDE0FF;
                text-align: right;

                .time {
                  text-align: right;
                  color: #9ea3ac !important;
                }

                .txt-wrapper {
                  background: #d0e6fd;
                  text-align: left;
                }
              }
            }
          }

          &.gary .msg-wrapper {
            //background: #f5f5f5;
          }
        }
      }
    }

    .chat-textarea {
      display: flex;
      flex-direction: column;
      min-height: 45px;
      border-top: 1px solid #ECECEC;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;

      .chat-btn-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 0 0;

        .left-wrapper {
          display: flex;
          align-items: center;

          .icon-item {
            display: flex;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            font-size: 24px;

            .iconfont {
              font-size: 22px;
              color: #333333;
            }
          }
        }

        .right-wrapper {
          position: relative;
          padding-right: 20px;

          .icon-item {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #333;
            cursor: pointer;

            span {
              margin-left: 10px;
            }
          }

          .transfer-box {
            z-index: 60;
            position: absolute;
            right: 1px;
            bottom: 43px;
            width: 140px;
            background: #fff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            padding: 16px;
          }

          .transfer-bg {
            z-index: 50;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: transparent;
          }
        }

        .emoji-box {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(-100%);
          display: flex;
          flex-wrap: wrap;
          width: 60%;
          padding: 15px 9px;
          box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.1);
          background: #fff;

          .emoji-item {
            margin-right: 13px;
            margin-bottom: 8px;
            cursor: pointer;

            &:nth-child(10n) {
              margin-right: 0;
            }
          }
        }
      }

      .textarea-box {
        flex: 1;
        display: flex;
        justify-content: space-between;
        min-height: 0;
        text-align: left;
      }
      .editable {
        flex: 1;
        padding: 4px 7px;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 14px;
        line-height: 30px;
        min-height: 30px;
        color: #515a6e;
        background: #f5f5f5;
        border-radius: 4px;
        &:focus-visible {
          outline: 0;
        }
        margin-right: 10px;
      }

      .send-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.fenge {
  width: 5px;
  font-size: 0px;
  height: 10000px;
  background: #f5f5f5;
}
.fenge:hover {
  cursor: col-resize;
}
.right-content {
  //width: 300px;
  min-width: 200px;
  //position: fixed;
  //right: 20px; top: 20px;
  //border: 2px solid cornflowerblue;
  //border-radius: 4px;
  //box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.5);
  //z-index: 5000;
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
  }
}

.bg {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}


.right_menu {
  position: relative;

  .crmchat_link {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    span {
      color: #ccc;
    }

    span:hover {
      color: #007aff;
    }
  }
}
</style>
