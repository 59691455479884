<template>
  <el-dialog
    title="查看IP"
    :visible.sync="dialogVisible"
    center
    width="800px"
    :before-close="handleClose">

    <el-form>
      <el-form-item label="用户ID">
        <el-input v-model="searchForm.user_id" style="width: 180px; margin-right: 10px" placeholder="用户ID"/>
        <el-button type="danger" @click="handleSearch">搜索</el-button>

        <el-input v-model="ipForm.ip" style="width: 180px; margin-right: 10px" placeholder="IP"/>
        <el-button type="danger" @click="handleShowIp">查看关联</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="datas"
      border>
      <el-table-column
        prop="user_id"
        label="UserID"
        width="120">
      </el-table-column>
      <el-table-column
        prop="ip"
        label="IP"
        width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="showUserById(scope.row.ip)">{{scope.row.ip}}</el-button>
          <br>{{scope.row.create_time}}
        </template>
      </el-table-column>
      <el-table-column
        prop="region"
        label="归属地">
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
    <dialog-user-by-ip ref="dialogUserByIp" :append-to-body="true"></dialog-user-by-ip>
  </el-dialog>
</template>

<script>
import DialogUserByIp from "@/views/manage/components/dialog-user-by-ip";
import { requestHttp } from "@/api/main";

export default {
  name: "DialogIp",
  components: { DialogUserByIp },
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      messageId: '',
      datas: [],
      searchForm: {
        user_id: ''
      },
      ipForm: {
        ip: ''
      }
    }
  },
  methods: {
    toggle(userId) {
      if (userId) {
        this.searchForm.user_id = userId
        this.dialogVisible = true
        this.handleSearch()
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleShowIp() {
      if (this.ipForm.ip != '') {
        this.showUserById(this.ipForm.ip)
      }
    },
    showUserById(ip) {
      this.$refs.dialogUserByIp.toggle(ip)
    },

    handleSearch() {
      const that = this
      requestHttp('agent/get_ip_region', this.searchForm).then((res) => {
        if (res.code == 200) {
          that.datas = res.data
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
  .green {
    color: #35b65a !important;
  }
  .red {
    color: #ff494a !important;
  }
</style>
