<template>
  <el-dialog
    title="禁言群员"
    :visible.sync="dialogVisible"
    center
    width="840px"
    :before-close="handleClose">

    <el-transfer
      v-model="values"
      style="width: 800px"
      filterable
      :filter-method="filterMethod"
      filter-placeholder="输入搜索"
      :render-content="renderFunc"
      :titles="['群成员', '禁言']"
      :props="{
        key: 'user_id',
        label: 'nickname'
      }"
      :data="datas">
    </el-transfer>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { thumb } from "@/utils/utils";

export default {
  name: "DialogGroupUserMute",
  data() {
    return {
      dialogVisible: false,
      groupId: 0,
      datas: [],
      values: [],
      renderFunc(h, option) {
        const avatar = thumb(option.thumb_avatar)
        return <span style="display: flex;"><img v-click="handleMute(option.user_id)" src={ avatar } style="width: 30px; height: 30px; margin-right: 5px;"/>{ option.nickname }</span>;
      }
    }
  },
  methods: {
    thumb,
    toggle(groupId) {
      if (groupId) {
        this.groupId = groupId
        this.dialogVisible = true
        this.fetchData()
      }
    },
    handleClose() {
      this.dialogVisible = false
    },

    handleTabsClick(e) {
      if (e.name == 'second') {
        this.fetchData()
      }
    },
    filterMethod(query, item) {
      return item.nickname.indexOf(query) > -1;
    },
    fetchData() {
      const that = this
      requestHttp('agent/group_users', {
        'group_id': that.groupId
      }).then((res) => {
        if (res.code == 200) {
          that.datas = res.data.datas
          that.values = []
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleSubmit() {
      const that = this
      this.$confirm('确定禁言该用户？', '提示').then(function () {
        requestHttp('agent/switch_mute', {
          'user_ids': that.values,
          'is_mute': 1
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success({ message: res.message, offset: 100 })
            that.values = []
          } else {
            that.$message.error({ message: res.message, offset: 100 })
          }
        })
      })
    }
  }
}
</script>

