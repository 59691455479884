<template>
  <el-dialog
    title="详情"
    :visible.sync="dialogVisible"
    center
    width="500px"
    :before-close="handleClose">

    <div title="基本资料">
      <div class="example">
        <el-form ref="baseForm" label-width="120px" v-if="detail.user_id !== '' || detail.group_id !== ''">
          <el-form-item prop="avatar" label="头像">
            <el-image :src="thumb(detail.thumb_avatar)" :preview-src-list="[thumb(detail.avatar)]" style="width: 75px; height: 75px"/>
          </el-form-item>
          <el-form-item label="用户ID" v-if="conversation.type == 'private'">
            {{detail.user_id}}
          </el-form-item>
          <el-form-item label="用户昵称 " v-if="conversation.type == 'private'">
            {{detail.nickname}}
          </el-form-item>
          <el-form-item label="性别 " v-if="conversation.type == 'private'">
            <span v-if="detail.gender == 1">男</span>
            <span v-if="detail.gender == 2">女</span>
            <span v-if="detail.gender == 0 || detail.gender == null">未知</span>
          </el-form-item>
          <el-form-item label="生日 " v-if="conversation.type == 'private'">
            {{detail.birthday}}
          </el-form-item>
          <el-form-item label="个性签名" v-if="conversation.type == 'private'">
            {{detail.motto}}
          </el-form-item>
          <el-form-item label="群名" v-if="conversation.type == 'group'">
            {{detail.name}}
          </el-form-item>
          <el-form-item label="群介绍" v-if="conversation.type == 'group'">
            {{detail.introduction}}
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { blobToBase64, thumb } from "@/utils/utils";

export default {
  name: "DialogCreateGroup",
  data() {
    return {
      dialogVisible: false,
      conversation: {
        'type': 'private'
      },
      detail: {
        user_id: '',
        group_id: ''
      }
    }
  },
  methods: {
    thumb,
    toggle(conversation) {
      if (conversation) {
        this.conversation = conversation
        this.fetchData()
        this.dialogVisible = true
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    fetchData() {
      const that = this
      requestHttp('conversation/profile', {
        userId: this.conversation.friend_user_id,
        conversationId: this.conversation.conversationId
      }).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
          return;
        }
        that.detail = res.data
      })
    }
  }
}
</script>

<style scoped>
.avatar-upload {
  text-align: center;
  padding-bottom: 20px;
}
</style>
