<template>
  <el-dialog
    title="修改群聊"
    :visible.sync="dialogVisible"
    center
    width="500px"
    :before-close="handleClose">

    <div title="基本资料">
      <div class="example">
        <el-form ref="baseForm" label-width="120px" :modelValue="baseFormData">
          <el-form-item label="群名" required>
            <el-input v-model="baseFormData.name" placeholder="请输入昵称" />
          </el-form-item>
          <el-form-item label="群介绍">
            <el-input type="textarea" v-model="baseFormData.introduction" placeholder="请输入群介绍" />
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import { blobToBase64, thumb } from "@/utils/utils";

export default {
  name: "DialogEditGroup",
  data() {
    return {
      dialogVisible: false,
      baseFormData: {
        group_id: '',
        avatar: '',
        name: '',
        join_mode: 2,
        introduction: ''
      }
    }
  },
  methods: {
    thumb,
    toggle(conversation) {
      if (conversation) {
        this.baseFormData.group_id = conversation.conversationId;
        this.baseFormData.avatar = conversation.avatar
        this.baseFormData.name = conversation.title
        this.baseFormData.join_mode = 2
        this.baseFormData.introduction = conversation.introduction
        this.dialogVisible = true
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    uploadAvatar(file) {
      const that = this
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
        return false
      }

      blobToBase64(file, function (res) {
        that.baseFormData.avatar = res
      });

      return false;
    },
    handleSave() {
      // setSendSettingForm.default
      const that = this
      this.baseFormData.user_ids = this.userId
      requestHttp('group/update', this.baseFormData).then((res) => {
        if (res.code == 200) {
          that.$message.success(res.message)
          that.handleClose()
        } else {
          that.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style scoped>
.avatar-upload {
  text-align: center;
  padding-bottom: 20px;
}
</style>
